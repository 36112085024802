import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../layouts/index"
import SEO from "../components/seo"
import config from "../config/site"

class BlogFr extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
      postsPerPage: config.postsPerPage,
      postsIncrementBy: config.postsIncrementBy,
      counter: 0,
    }
  }

  componentDidMount() {
    const { data, postsPerPage } = this.state
    const posts = data.allWordpressPost.edges
    this.setState({ counter: posts.slice(0, postsPerPage).length })
  }

  loadMore = () => {
    const { data, postsPerPage, postsIncrementBy } = this.state
    const posts = data.allWordpressPost.edges
    this.setState({
      postsPerPage: postsPerPage + postsIncrementBy,
      counter: posts.slice(0, postsPerPage + postsIncrementBy).length,
    })
  }

  render() {
    const { data, postsPerPage, counter } = this.state
    const posts = data.allWordpressPost.edges

    return (
      <Layout cssClass="posts blog-style">
        <SEO title="Blog" />
        <div className="main-content">
          <h1 className="heading-title">Blog</h1>
          <div className="posts-wrapper">
            {posts.slice(0, postsPerPage).map(({ node }) => (
              <div className="post" key={node.slug}>
                <div className="post-content">
                  <ul className="post-categories">
                    {node.post_categories.map(category => (
                      <li
                        className="post-category"
                        key={category.color}
                        style={{
                          color: `${category.text_color}`,
                          backgroundColor: `${category.color}`,
                          borderColor: `${category.color}`,
                        }}
                      >
                        <Link
                          className="post-category-link"
                          to={`/blog/categories/${category.slug}`}
                          style={{ textDecoration: `none` }}
                        >
                          {category.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <Link className="post-link" to={`/blog/${node.slug}`} style={{ textDecoration: `none` }}>
                    <h3 className="post-title" dangerouslySetInnerHTML={{ __html: node.title }} />
                    <div
                      className="post-excerpt"
                      dangerouslySetInnerHTML={{ __html: `${node.excerpt.substring(0, 250)}` }}
                    />
                  </Link>
                  <span className="post-author">
                    <div className="post-author-link">
                      <div className="author-thumb">
                        <img src={node.author_meta.avatar_url} alt="" />
                      </div>
                      <div>
                        <span className="author-name">{node.author_meta.display_name}</span>
                        <span className="post-date">
                          {new Date(node.date).toLocaleDateString("fr-FR", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            ))}
          </div>
          {counter < posts.length && (
            <div className="load-more-wrapper">
              <a role="button" tabIndex={0} onClick={this.loadMore} onKeyUp={() => false} className="load-more">
                Voir plus
              </a>
            </div>
          )}
        </div>
      </Layout>
    )
  }
}

export default BlogFr

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
          author_meta {
            display_name
            avatar_url
            twitter_url
            linkedin_url
          }
          post_categories {
            text_color
            color
            title
            slug
          }
        }
      }
    }
  }
`
BlogFr.propTypes = {
  data: PropTypes.object.isRequired,
}
